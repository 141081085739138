import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_THEME_API_URL

export interface CompanyListResp {
  [cin: string]: {
    companyName: string
    fyList: string[]
  }
}

export const getCompaniesList = async (): Promise<CompanyListResp> => {
  return await axios
    .get(`${API_URL}/company/list`)
    .then((d: AxiosResponse<CompanyListResp>) => d.data)
    .then((res) => res)
}
