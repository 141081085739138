import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_THEME_API_URL

export interface Currency {
  dbId?: number
  currencyCode: string
  formalName: string
  currencySymbol: string
  exchangeDate: string
  exchangeRate: string
}

export const getCurrency = async (companyId: string): Promise<Currency[] | undefined> => {
  return await axios
    .get(`${API_URL}/product-master/currency/${companyId}`)
    .then((response: AxiosResponse<Currency[]>) => response.data)
    .then((response: Currency[]) => response)
}

export interface AllCurrencyResp {
  data: CurrencyDetails[]
}

export interface CurrencyDetails {
  currency_symbol: string
  country: string
  currency_code: string
  currency: string
}

export const getAllCurrencyOptions = async (): Promise<CurrencyDetails[] | undefined> => {
  return await axios
    .get(`${API_URL}/utils/currency`)
    .then((response: AxiosResponse<AllCurrencyResp>) => response.data)
    .then((response: AllCurrencyResp) => response.data)
}

interface CreateCurrencyBody {
  dbId?: number
  companyId: string
  currencyCode: string
  formalName: string
  currencySymbol: string
  decimal: number
}

export const createCurrency = async (
  body: CreateCurrencyBody
): Promise<{detail: string} | undefined> => {
  return await axios
    .post(`${API_URL}/product-master/currency/save`, body)
    .then((response: AxiosResponse<{detail: string}>) => response.data)
    .then((response: {detail: string}) => response)
}

export const deleteCurrency = async (currency: string): Promise<{detail: string} | undefined> => {
  return await axios
    .delete(`${API_URL}/product-master/currency/${currency}`)
    .then((response: AxiosResponse<{detail: string}>) => response.data)
    .then((response: {detail: string}) => response)
}

export interface ExchangeRatesResp {
  exchangeRatesData: {
    currencyCode: string
    exchangeRate: number | null
  }[]
}

export const getExchangeRates = async (
  companyId: string,
  fy: string,
  exchangeDate: string
): Promise<ExchangeRatesResp | undefined> => {
  return await axios
    .get(`${API_URL}/product-master/data/exchange-rate/${companyId}/${fy}/${exchangeDate}`)
    .then((response: AxiosResponse<ExchangeRatesResp>) => response.data)
    .then((response: ExchangeRatesResp) => response)
}

export interface UpdateExchangeRatesParams {
  // dbId: number;
  companyId: string
  exchangeRateDate: string
  filledExchangeRatesTable: {
    currencyCode: string
    previousExchangeRate: number | null
    currentExchangeRate: number | null
  }[]
}

export const updateExchangeRates = async (
  params: UpdateExchangeRatesParams
): Promise<{detail: string} | undefined> => {
  return await axios
    .post(`${API_URL}/product-master/exchange-rate/save`, params)
    .then((response: AxiosResponse<{detail: string}>) => response.data)
    .then((response: {detail: string}) => response)
}

export const downloadPdf = async (
  records: Currency[],
  companyId: string,
  fyYear: string
): Promise<any | undefined> => {
  return await axios.post<any>(`${API_URL}/pdfDownload`, {
    records,
    companyId,
    fyYear,
  })
}
